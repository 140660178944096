<template>
  <v-row>
    <v-col cols="12" sm="12" md="11" lg="11">
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Customer</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
              <span>{{ form.name }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>NPWP</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
              <span>{{ form.npwp }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>NIK</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
              <span>{{ form.nik }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Address NPWP</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
              <span>{{ form.addressNpwp }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>WAPU</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
              <v-radio-group v-model="form.wapu" row disabled>
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "detail-npwp",
  props: {
    form: Object,
    id: Number,
  },
};
</script>

<style></style>
