<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Customer</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ form.name }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>NPWP</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  dense
                  flat
                  outlined
                  background-color="white"
                  v-model="form.npwp"
                  counter="20"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>NIK</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  dense
                  flat
                  outlined
                  background-color="white"
                  v-model="form.nik"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Address NPWP</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-textarea
                  outlined
                  flat
                  rows="3"
                  background-color="white"
                  v-model="form.addressNpwp"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>WAPU</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-radio-group v-model="form.wapu" row>
                  <v-radio label="Yes" :value="true"></v-radio>
                  <v-radio label="No" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "form-npwp",
  props: {
    form: Object,
  },
  data() {
    return {
      valid: true,
    };
  },
  methods: {
    async update() {
      this.$store.commit("SET_LOADER", true);
      if (this.$refs.form.validate()) {
        await this.$store
          .dispatch("external/updateNpwp", this.form)
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      } else {
        this.$store.dispatch("toast", { type: "error", message: "All Form is required" });
      }
    },
  },
};
</script>

<style></style>
